<template>
  <div class="tab-step">
    <ul class="tab-step__list">
      <li
        class="tab-step__item"
        v-for="[tabKey, tab] of tabs"
        :key="tabKey"
        @click="changeTab(tab)"
        :class="[`tab-step__item_${tab.state}`, { 'tab-step__item_nav': tabNavigation }]"
      >
        <img v-if="isReadyTab(tab)" class="tab-step__icon" src="@/assets/images/svg/daw.svg" />
        <span v-else>{{ `${tab.id}.` }}</span>

        <p class="tab-step__text">
          {{ tab.title }}
        </p>
      </li>
    </ul>

    <div class="tab-step__content">
      <keep-alive>
        <component ref="dynamicComponent" :is="activeTab.instance" @unlock="isLoading = false"></component>
      </keep-alive>
    </div>

    <div v-if="!tabNavigation" class="tab-step__events">
      <div class="tab-step__back">
        <ButtonComponent mod="blue-color" @click="back">{{ backButtonName }}</ButtonComponent>
      </div>
      <div class="tab-step__forward">
        <ButtonComponent mod="gradient-bg" @click="next" :disabled="isLoading">{{ nextButtonName }}</ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ButtonComponent from '../../ButtonComponent.vue';

  const tabStates = {
    NON_ACTIVE: 'non-active',
    ACTIVE: 'active',
    READY: 'ready',
  };

  export default {
    name: 'TabStep',
    components: {
      ButtonComponent,
    },
    props: {
      confirmButton: {
        type: String,
        default: 'Создать',
      },
      components: {
        type: Array,
        default() {
          return [];
        },
      },
      cancelRoute: {
        type: Object,
        default: null,
      },
      tabNavigation: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tabs: new Map(),
        activeTab: {
          id: null,
          title: '',
          state: tabStates.NON_ACTIVE,
          instance: null,
        },
        isLoading: false,
      };
    },
    setup: () => ({ v$: useVuelidate() }),
    mounted() {
      this.components.forEach((component, index) => {
        const isActive = index === 0;
        const id = index + 1;

        this.tabs.set(id, {
          id,
          title: component.props.tabTitle,
          state: tabStates.NON_ACTIVE,
          instance: component.component,
        });

        if (isActive) {
          this.setActiveComponentInfo(this.tabs.get(id));
        }
      });
    },
    emits: ['update:activeTab', 'cancel', 'step'],
    computed: {
      backButtonName() {
        return this.activeTab.id === 1 ? 'Отмена' : 'Назад';
      },
      nextButtonName() {
        return this.tabs.has(this.activeTab.id + 1) ? 'Далее' : this.confirmButton;
      },
    },
    methods: {
      isReadyTab(tab) {
        return tab.state === tabStates.READY;
      },
      back() {
        const prevId = this.activeTab.id - 1;

        if (prevId > 0) {
          this.activeTab.state = tabStates.NON_ACTIVE;
          const prevComponent = this.tabs.get(prevId);
          this.setActiveComponentInfo(prevComponent);
          this.$emit('step', prevId);
        } else if (this.cancelRoute) {
          this.$router.push(this.cancelRoute);
        } else {
          this.$emit('cancel');
          this.v$.$reset();
        }
      },
      next() {
        const nextId = this.activeTab.id + 1;
        const nextComponent = this.tabs.get(nextId);

        this.isLoading = !nextComponent;

        if (this.$refs.dynamicComponent.validate && !this.$refs.dynamicComponent.validate()) {
          return;
        }

        if (nextComponent) {
          this.activeTab.state = tabStates.READY;
          this.setActiveComponentInfo(nextComponent);
          this.$emit('step', nextId);
        }
      },
      setActiveComponentInfo(tab) {
        this.activeTab = tab;
        this.activeTab.state = tabStates.ACTIVE;
      },
      changeTab(tab) {
        if (this.tabNavigation) {
          this.activeTab.state = tabStates.NON_ACTIVE;
          this.setActiveComponentInfo(tab);
          this.$emit('step', tab.id);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tab-step {
    &__list {
      display: flex;
      gap: 24px;
      margin: 0 0 24px 0;

      @media (max-width: $laptop) {
        flex-direction: column;
        gap: 4px;
      }
    }

    &__item {
      display: flex;
      gap: 8px;
      color: $dark-grey-1;

      &_active {
        color: $blue;
      }

      &_ready {
        color: $green-2;
      }

      &_nav {
        cursor: pointer;
      }
    }

    &__events {
      display: flex;
      justify-content: space-between;
      margin: 40px 0 0 0;
    }
  }
</style>
