import { mapActions, mapGetters } from 'vuex';

import Constants from '@/common/constants';

import { INITIATIVE_REGISTRY_GETTERS_NAME } from '@/modules/measure/store/initiativeRegistry/name';

import { ID_STATUS_ACTIONS, INITIATIVE_STATUSES } from '../utils/constants';

import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

export default {
  data() {
    return {
      sortClassName: Constants.sort,
      changeLog: {
        isActive: false,
        filter: {},
        id: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      measureOrganProtectedMarketList: DIRECTIVES_GETTERS_NAME.getNSIMeasureOrganProtectedMarketList,
      industries: DIRECTIVES_GETTERS_NAME.getNSIIndustryList,
      moveDirections: DIRECTIVES_GETTERS_NAME.getNSIMoveDirectionsList,
      getCountriesMultiSelect: DIRECTIVES_GETTERS_NAME.getCountriesMultiSelect,
      countryCoveragesList: DIRECTIVES_GETTERS_NAME.getNSICountryCoveragesList,
      getTtrStatusGroupOptions: DIRECTIVES_GETTERS_NAME.getTtrStatusGroupOptions,
      measureTypeList: DIRECTIVES_GETTERS_NAME.getNSIMeasureTypesList,
      getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
      getUserProfile: 'auth/oidc/getUserProfile',
    }),

    getStatusOptions() {
      return Constants.statusOptions.map((i) => ({ value: i.id, label: i.text }));
    },
    isProcessApplicantStatus() {
      return (
        this.getInitiative.statusId === INITIATIVE_STATUSES.processApplicantExport ||
        this.getInitiative.statusId === INITIATIVE_STATUSES.processApplicantImport
      );
    },
    isInexpedientApplicantStatus() {
      return (
        this.getInitiative.statusId === INITIATIVE_STATUSES.inexpedientExport ||
        this.getInitiative.statusId === INITIATIVE_STATUSES.inexpedientImport
      );
    },
    isProcessApplicantAndInexpedientStatus() {
      return this.isProcessApplicantStatus || this.isInexpedientApplicantStatus;
    },
    isCanInitiativeEditing() {
      return this.getInitiative?.employee?.id === this.getUserProfile?.employee?.id && this.isProcessApplicantStatus;
    },
    isInitiativeExist() {
      return !!this.getInitiative.id;
    },
    isCanInitiativeInput() {
      return this.isCanInitiativeEditing || !this.isInitiativeExist;
    },
  },
  methods: {
    ...mapActions({
      getNSIMeasureOrganProtectedMarket: DIRECTIVES_ACTIONS_NAME.getNSIMeasureOrganProtectedMarket,
      getNSIIndustry: DIRECTIVES_ACTIONS_NAME.getNSIIndustry,
      getNSIMoveDirections: DIRECTIVES_ACTIONS_NAME.getNSIMoveDirections,
      countriesGet: DIRECTIVES_ACTIONS_NAME.countriesGet,
      getNSICountryCoverages: DIRECTIVES_ACTIONS_NAME.getNSICountryCoverages,
      getNSITtrStatus: DIRECTIVES_ACTIONS_NAME.getNSITtrStatus,
      getNSIMeasureTypes: DIRECTIVES_ACTIONS_NAME.getNSIMeasureTypes,
    }),

    getClassStatus(statusID) {
      switch (statusID) {
        case ID_STATUS_ACTIONS.create:
          return 'green';
        case ID_STATUS_ACTIONS.edit:
          return 'blue';
        default:
          return '';
      }
    },

    valueComparison(arrayFirst, arraySecond) {
      return String(arrayFirst) == String(arraySecond);
    },

    getClassChange(index, el, params) {
      if (!index) return '';
      let oldValue = '';
      let newValue = '';
      if (!params?.array) {
        if (params?.name) {
          oldValue = this.page.items[index - 1].entityDto[el]
            ? this.page.items[index - 1].entityDto[el][params.name]
            : '';
          newValue = this.page.items[index].entityDto[el] ? this.page.items[index].entityDto[el][params.name] : '';
        } else {
          oldValue = this.page.items[index - 1].entityDto[el];
          newValue = this.page.items[index].entityDto[el];
        }
      }
      if (params?.array) {
        const oldArray = this.page.items[index - 1].entityDto[el];
        const newArray = this.page.items[index].entityDto[el];
        oldValue = oldArray.map((i) => i[params.name]);
        newValue = newArray.map((i) => i[params.name]);
      }

      return !this.valueComparison(oldValue, newValue) ? 'red' : '';
    },

    switchChangelog(id) {
      this.changeLog.id = id;
      this.request.pageNumber = 1;
      // При открытии журнала изменений, по умолчанию сортировка по дате изменения (сначала отображать последние изменения)
      this.request.sortColumn = 17;
      this.request.sortOrder = 1;
      this.loadChangeLog();
    },

    changeFilterChangeLog(filter) {
      this.request.pageNumber = 1;
      this.changeLog.filter = filter;
      this.loadChangeLog();
    },

    changeSortingChangeLog(sort) {
      const { sortColumn, sortOrder } = sort;
      this.request.sortColumn = sortColumn;
      this.request.sortOrder = sortOrder;
      this.loadChangeLog();
    },

    closeChangeLog() {
      this.changeLog.isActive = false;
      this.changeLog.filter = {};
      this.request.sortColumn = 0;
      this.request.sortOrder = 0;
    },
  },
  watch: {},
};
