<template>
  <div class="initiative-main-info">
    <div class="initiative-main-info__body">
      <div v-if="mainInfo" class="custom-form__block custom-form__block_cellular">
        <InputBlock title="Данные об организации" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <ComponentInput
            v-if="isCanInitiativeInput"
            :model-value="mainInfo.employee?.organization?.nameFull"
            mod="rectangular"
            required
            disabled
          />
          <p v-else>{{ mainInfo.employee?.organization?.nameFull ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Заявитель" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <ComponentInput
            v-if="isCanInitiativeInput"
            :model-value="mainInfo.employee?.fullName"
            mod="rectangular"
            required
            disabled
          />
          <p v-else>{{ mainInfo.employee?.fullName ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Адрес" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <ComponentInput
            v-if="isCanInitiativeInput"
            :model-value="mainInfo.employee?.organization?.address"
            mod="rectangular"
            required
            disabled
          />
          <p v-else>{{ mainInfo.employee?.organization?.address ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Телефон" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <ComponentInput
            v-if="isCanInitiativeInput"
            :model-value="mainInfo.employee?.organization?.phone"
            mod="rectangular"
            type="phone"
            required
            disabled
          />
          <p v-else>{{ getPreparedPhone ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Общая информация о заявителе" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <ComponentInput
            v-if="isCanInitiativeInput"
            v-model="mainInfo.applicantGeneralInformation"
            placeholder="Указывается основная информация, характеризующая деятельность Заявителя, доля на рынке соответствующих товаров, иная обосновывающая целесообразность предлагаемой меры информация о Заявителе"
            mod="rectangular no-resize"
            required
            type="textarea"
          />
          <p v-else>{{ mainInfo.applicantGeneralInformation ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Дата подачи заявления" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <ComponentInput
            v-if="isCanInitiativeInput"
            :model-value="getPreparedApplicationSubmissionDate"
            mod="rectangular"
            required
            disabled
          />
          <p v-else>{{ getPreparedApplicationSubmissionDate ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Тип меры" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <SelectSingleComponentCheckbox
            v-if="isCanInitiativeInput"
            v-model.number="mainInfo.measureTypeId"
            placeholder="Выберите тип"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="measureTypeList"
            value-type="number"
            required
          />
          <p v-else>{{ mainInfo.measureType?.name ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Направление перемещения" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <SelectSingleComponentCheckbox
            v-if="isCanInitiativeInput"
            v-model.number="mainInfo.nsiTradingOperationId"
            placeholder="Выберите направление"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="movingDirectionOptions"
            value-type="number"
            required
          />
          <p v-else>{{ getPreparedTradingOperation }}</p>
        </InputBlock>

        <InputBlock
          title="Товар (обобщающее наименование)"
          :required="isCanInitiativeInput"
          :bold="!isCanInitiativeInput"
        >
          <ComponentInput
            v-if="isCanInitiativeInput"
            v-model="mainInfo.tovar"
            placeholder="Введите"
            mod="rectangular"
            required
            tip="В соответствии с положениями Договора о ЕАЭС установление тарифных квот возможно только в отношении отдельных видов сельскохозяйственных товаров"
            :accessed-tip="isAccessedProductTip"
          />
          <p v-else>{{ mainInfo.tovar ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Цель" :required="isCanInitiativeInput" :bold="!isCanInitiativeInput">
          <ComponentInput
            v-if="isCanInitiativeInput"
            v-model="mainInfo.purpose"
            placeholder="Введите"
            mod="rectangular"
            required
          />
          <p v-else>{{ mainInfo.purpose ?? '-' }}</p>
        </InputBlock>

        <InputBlock title="Статус" :required="!isReadonlyStatus" :bold="isReadonlyStatus">
          <p v-if="isReadonlyStatus">{{ mainInfo.status.name ?? '-' }}</p>
          <SelectMultiComponent
            v-else
            v-model="mainInfo.statusId"
            :options="getStatusGroupOptions"
            placeholder="Выберите статус"
            modifier="rectangular"
            classSelect="visible-clear"
            groups
            :disabled="isDisableStatuses"
          />
        </InputBlock>

        <InputBlock
          title="Ответственный сотрудник"
          :required="!isProcessApplicantAndInexpedientStatus"
          :bold="isProcessApplicantAndInexpedientStatus"
        >
          <p v-if="isProcessApplicantAndInexpedientStatus">{{ mainInfo.responsibleEmployee?.fullName ?? '-' }}</p>
          <template v-else>
            <SelectMultiComponent
              v-if="isInitiativeExist"
              v-model="mainInfo.responsibleEmployeeId"
              :options="getResponsibleEmployeesOptions"
              placeholder="Выберите ответственного"
              modifier="rectangular"
              classSelect="visible-clear"
              :disabled="!isEditableResponsibleEmployees"
            />
            <ComponentInput
              v-else
              :model-value="mainInfo.responsibleEmployee?.fullName"
              mod="rectangular"
              required
              disabled
            />
          </template>
        </InputBlock>

        <InputBlock title="Примечания" :bold="isProcessApplicantAndInexpedientStatus">
          <p v-if="isProcessApplicantAndInexpedientStatus">{{ mainInfo.notes ?? '-' }}</p>
          <ComponentInput v-else v-model="mainInfo.notes" mod="rectangular no-resize" type="textarea" />
        </InputBlock>
      </div>

      <div v-if="!isCanInitiativeInput && !isInexpedientApplicantStatus" class="initiative-main-info__buttons">
        <ButtonComponent
          @click="changeInitiative"
          mod="gradient-bg"
          :disabled="isLoadingChangeButton || isDisableChangeButton"
          >Изменить</ButtonComponent
        >
        <ButtonComponent @click="initMainInfo">Отмена</ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import InputBlock from '@/common/components/redesigned/InputBlock.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import duplicateMethods from '@/modules/measure/mixins/duplicateMethods';

  import Api from '../../../api/reqsinitiativesttr';
  import {
    INITIATIVE_REGISTRY_ACTIONS_NAME,
    INITIATIVE_REGISTRY_GETTERS_NAME,
    INITIATIVE_REGISTRY_MUTATIONS_NAME,
  } from '../../../store/initiativeRegistry/name';
  import {
    INITIATIVE_STATUSES,
    MEASURE_TYPE_IDS,
    MOVING_DIRECTION,
    movingDirectionOptions as globalMovingDirectionOptions,
    movingDirectionOptions,
  } from '../../../utils/constants';

  import { DIRECTIVES_ACTIONS_NAME } from '@/store/directives';
  import { DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  const processApplicantStatuses = [
    INITIATIVE_STATUSES.processApplicantExport,
    INITIATIVE_STATUSES.processApplicantImport,
  ];
  const inexpedientStatuses = [INITIATIVE_STATUSES.inexpedientExport, INITIATIVE_STATUSES.inexpedientImport];

  export default {
    name: 'InitiativeMainInfo',
    components: { SelectSingleComponentCheckbox, ComponentInput, InputBlock, ButtonComponent, SelectMultiComponent },
    mixins: [security, duplicateMethods],
    data() {
      return {
        mainInfo: {
          organizationInfo: '',
          phone: '',
          applicationSubmissionDate: moment().format('YYYY-MM-DD'),
          created: null,
          applicantGeneralInformation: '',
          measureTypeId: null,
          userId: null,
          nsiTradingOperationId: null,
          tovar: null,
          purpose: '',
          statusId: null,
          status: {
            name: '',
          },
          employee: {
            fullName: '',
            organization: {
              nameFull: '',
              phone: '',
              address: '',
            },
          },
          employeeId: null,
          notes: '',
          measureType: {
            name: '',
          },
          responsibleEmployeeId: null,
          responsibleEmployee: {
            fullName: '',
          },
        },
        measureTypeList: [],
        movingDirectionOptions: globalMovingDirectionOptions,
        responsibleEmployees: [],
        isLoadingChangeButton: false,
        isDisableChangeButton: true,
      };
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
      ...mapGetters({
        getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
        getTtrStatusGroupOptions: DIRECTIVES_GETTERS_NAME.getTtrStatusGroupOptions,
        getUserProfile: 'auth/oidc/getUserProfile',
      }),
      isReadonlyStatus() {
        return this.isInexpedientApplicantStatus || this.isCanInitiativeEditing;
      },
      getStatusGroupOptions() {
        if (processApplicantStatuses.includes(this.getInitiative.statusId)) {
          return this.getStatusGroupOptionsWithApplicant;
        } else {
          return this.getStatusGroupOptionsWithoutApplicant;
        }
      },
      getStatusGroupOptionsWithoutApplicant() {
        return this.getTtrStatusGroupOptions.map((item) => ({
          ...item,
          options: item.options.filter((item) => !processApplicantStatuses.includes(item.value)),
        }));
      },
      getStatusGroupOptionsWithApplicant() {
        const validStatuses = [...inexpedientStatuses, ...processApplicantStatuses];
        return this.getTtrStatusGroupOptions.map((item) => ({
          ...item,
          options: item.options.filter((item) => validStatuses.includes(item.value)),
        }));
      },
      isDisableStatuses() {
        return inexpedientStatuses.includes(this.getInitiative.statusId);
      },
      isAccessedProductTip() {
        return (
          this.mainInfo.measureTypeId === MEASURE_TYPE_IDS.TARIFF_QUOTA &&
          this.mainInfo.nsiTradingOperationId === MOVING_DIRECTION.IMPORT
        );
      },
      getPreparedApplicationSubmissionDate() {
        return moment(this.mainInfo.created ?? this.mainInfo.applicationSubmissionDate).format('DD.MM.YYYY');
      },
      getPreparedPhone() {
        return Utils.getPreparedMobilePhone(this.mainInfo.employee?.organization?.phone) ?? '-';
      },
      getPreparedTradingOperation() {
        const result = movingDirectionOptions.find((item) => item.id === this.mainInfo.nsiTradingOperationId);
        return result ? result.text : '-';
      },
      getResponsibleEmployeesOptions() {
        const result = this.responsibleEmployees.map((item) => ({
          value: item.id,
          label: item.fullName,
        }));

        if (
          this.isInitiativeExist &&
          !this.responsibleEmployees.some((item) => item.id === this.mainInfo.responsibleEmployeeId) &&
          this.mainInfo.responsibleEmployee
        ) {
          result.push({
            value: this.mainInfo.responsibleEmployee.id,
            label: this.mainInfo.responsibleEmployee.fullName,
          });
        }

        return result;
      },
      isEditableResponsibleEmployees() {
        if (!this.getUserProfile || !this.getUserProfile.roleIds) return false;
        return (
          this.getUserProfile.roleIds.includes(Constants.Permissions.AccessEditableResponsibleEmployee) &&
          !this.isProcessApplicantStatus
        );
      },
    },
    async created() {
      this.checkPermissionAndRedirect(Constants.Permissions.RequestInitiativeTtrCreateAdmin);

      this.isInitiativeExist ? await this.loadReadonlyDictionaries() : await this.loadDictionaries();

      this.initMainInfo();

      if (!this.isInitiativeExist) {
        this.initResponsibleEmployee();
      }
    },
    deactivated() {
      this.v$.$reset();
    },
    methods: {
      ...mapActions({
        setUser: INITIATIVE_REGISTRY_ACTIONS_NAME.setUser,
        getNSITtrStatus: DIRECTIVES_ACTIONS_NAME.getNSITtrStatus,
        updateInitiative: INITIATIVE_REGISTRY_ACTIONS_NAME.updateInitiative,
      }),
      ...mapMutations({
        setMainInfo: INITIATIVE_REGISTRY_MUTATIONS_NAME.setMainInfo,
      }),
      /**
       * @public
       * Вызывается родительским компонентом пошагового таба
       */
      validate() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return false;
        }

        this.setMainInfo(this.mainInfo);

        return true;
      },
      async loadDictionaries() {
        try {
          await Promise.all([
            this.setUser(),
            this.getNSITtrStatus(),
            Utils.loadSelectOptions('measureTypes', this.measureTypeList, true),
          ]);
        } catch (error) {
          console.error(error.response.data);
        }
      },
      async loadReadonlyDictionaries() {
        try {
          await Promise.all([
            this.getNSITtrStatus(),
            this.setResponsibleEmployees(),
            Utils.loadSelectOptions('measureTypes', this.measureTypeList, true),
          ]);
        } catch (error) {
          console.error(error.response.data);
        }
      },
      initMainInfo() {
        this.mainInfo = Object.keys(this.mainInfo).reduce((obj, key) => {
          if (key in this.getInitiative) {
            obj[key] = this.getInitiative[key];
          }
          return obj;
        }, {});
        this.mainInfoCast = JSON.parse(JSON.stringify(this.mainInfo));
      },
      async initResponsibleEmployee() {
        try {
          const response = await Api.getRandomResponsibleEmployee();

          if (response.data) {
            this.mainInfo.responsibleEmployeeId = response.data.id;
            this.mainInfo.responsibleEmployee = response.data;
          }
        } catch (err) {
          console.error(err);
        }
      },
      async changeInitiative() {
        this.isLoadingChangeButton = true;
        const isSuccess = this.validate();

        try {
          if (isSuccess) {
            await this.updateInitiative();
            this.mainInfoCast = JSON.parse(JSON.stringify(this.mainInfo));
            this.isDisableChangeButton = true;
            Constants.alert.fire('Сохранение', 'Заявка успешно обновлена', 'success');
          }
        } catch (err) {
          console.error(err);
          this.setMainInfo(this.mainInfoCast);
          Constants.alert.fire('Ошибка', 'Не удалось обновить заявку', 'error');
        } finally {
          this.isLoadingChangeButton = false;
        }
      },
      async setResponsibleEmployees() {
        try {
          const response = await Api.getResponsibleEmployees();
          this.responsibleEmployees = response.data;
        } catch (err) {
          console.error(err);
        }
      },
    },
    watch: {
      mainInfo: {
        handler() {
          this.isDisableChangeButton = JSON.stringify(this.mainInfoCast) === JSON.stringify(this.mainInfo);
        },
        deep: true,
      },
      getInitiative() {
        this.initMainInfo();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';

  :deep(.button-redesign) {
    width: 100%;
  }
  .initiative-main-info {
    position: relative;

    &__buttons {
      position: absolute;
      left: 0;
      bottom: -80px;
      display: flex;
      gap: 8px;

      @media (max-width: $mobile-2) {
        width: 100%;
        flex-direction: column;
        bottom: -113px;

        :deep(.button-redesign) {
          width: 100%;
        }
      }
    }
  }
</style>
