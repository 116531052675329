<template>
  <div class="initiative-rate tab-step-form">
    <InitiativeRateTable v-model="rates" :readonly="!isCanInitiativeInput"></InitiativeRateTable>

    <ShowMoreButton v-if="isCanInitiativeInput" classButton="text-start" @onClick="addRate" isForeverVisible>
      <span>Добавить запись</span>
      <IconComponent name="plus-mini"></IconComponent
    ></ShowMoreButton>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';

  import ShowMoreButton from '@/common/components/redesigned/ShowMoreButton.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import duplicateMethods from '@/modules/measure/mixins/duplicateMethods';

  import {
    INITIATIVE_REGISTRY_ACTIONS_NAME,
    INITIATIVE_REGISTRY_GETTERS_NAME,
    INITIATIVE_REGISTRY_MUTATIONS_NAME,
  } from '../../../../store/initiativeRegistry/name';
  import InitiativeRateTable from './InitiativeRateTable.vue';

  import { NSITNVED_ACTIONS_NAME } from '@/store/nsitnved';

  export default {
    name: 'InitiativeRate',
    mixins: [duplicateMethods],
    components: {
      IconComponent,
      InitiativeRateTable,
      ShowMoreButton,
    },
    data() {
      return {
        rates: [],
      };
    },
    setup: () => ({ v$: useVuelidate() }),
    computed: {
      ...mapGetters({
        getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
      }),
    },
    created() {
      this.initRates();
    },
    deactivated() {
      this.v$.$reset();
    },
    methods: {
      ...mapMutations({
        setRates: INITIATIVE_REGISTRY_MUTATIONS_NAME.setRates,
      }),
      ...mapActions({
        setUser: INITIATIVE_REGISTRY_ACTIONS_NAME.setUser,
        getNSITnvedByIDs: NSITNVED_ACTIONS_NAME.getNSITnvedByIDs,
        updateInitiative: INITIATIVE_REGISTRY_ACTIONS_NAME.updateInitiative,
      }),
      /**
       * @public
       * Вызывается родительским компонентом пошагового таба
       */
      validate() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return false;
        }

        if (!this.rates.length) {
          Constants.alert.fire('Ошибка', 'Необходимо добавить ставку.', 'error');
          return false;
        }

        this.setRates(this.rates);

        return true;
      },
      addRate() {
        this.rates.push({
          tnvedId: null,
          customsDutyCurrentRate: '',
          proposedDutyRate: '',
          proposedSpecification: '',
          proposedTnvedName: '',
        });
      },
      initRates() {
        this.rates = JSON.parse(JSON.stringify(this.getInitiative.rates));
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';

  .initiative-rate {
    display: flex;
    flex-direction: column;

    :deep(.more-button) {
      button {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
</style>
